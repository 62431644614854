.LoginForm {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .LoginForm__error-message {
    color: #dc3545;
    margin-bottom: 1rem; }
  .LoginForm__buttons-wrap {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%; }
