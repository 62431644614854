/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
.ReceiptPage .page-content {
  padding: 2rem;
  background-color: #e9e9e9;
  -webkit-box-shadow: 0.25rem 0.25rem 0 0 #ccc;
  -moz-box-shadow: 0.25rem 0.25rem 0 0 #ccc;
  box-shadow: 0.25rem 0.25rem 0 0 #ccc; }

.ReceiptPage .page-footer {
  margin-top: 6rem;
  text-align: center; }
