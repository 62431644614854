/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
.SamCheckbox.custom-control {
  padding-left: 0; }

.SamCheckbox.has-label {
  padding-left: 3rem; }

.SamCheckbox .custom-control-label::before,
.SamCheckbox .custom-control-label,
.SamCheckbox .custom-control-label::after {
  min-width: 2rem;
  min-height: 2rem;
  padding-top: 0.5rem; }

.SamCheckbox .custom-control-label:hover {
  color: #0f7696; }

.SamCheckbox .custom-control-label::before {
  background-color: #fefefe;
  border: 1px solid #999; }

.SamCheckbox .custom-control-input ~ .custom-control-label::after {
  background-image: none !important;
  color: #128bb0;
  background-color: transparent; }

.SamCheckbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fefefe !important; }

.SamCheckbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  font-family: 'SamIcons';
  font-size: 1.2rem;
  padding: 0.2rem 0.4rem;
  content: '\E902';
  color: #128bb0;
  background-color: transparent; }

@media print {
  .SamCheckbox.d-print-checkbox-none.custom-control {
    padding-left: 0; }
  .SamCheckbox.d-print-checkbox-none .custom-control-label::before,
  .SamCheckbox.d-print-checkbox-none .custom-control-label::after {
    display: none !important; } }
