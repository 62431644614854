/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
.payment-section__spinner {
  margin: 0 auto;
  color: black;
  width: 8em;
  height: 8em; }

.summary-payment-section {
  margin-top: 3rem; }

.stripe-links {
  text-align: center;
  width: 100%;
  margin-top: 20px; }

.stripe-logo {
  height: 26px; }

.delimiter {
  margin-left: 5px;
  margin-right: 5px; }

.stripe-link {
  font-size: 12px;
  text-decoration: underline dotted; }
