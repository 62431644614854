.Summary__title {
  font-weight: 500; }

.Summary__logo {
  margin: 0 0 1rem;
  max-width: 100%;
  min-width: 100%; }

.Summary p {
  margin-bottom: 0; }

.Summary .provider-summary img {
  width: 100%;
  height: 100%; }
