/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
.OptionsSummaryItem {
  list-style: none;
  border-bottom: 1px solid #ccc;
  padding: 0.4rem 0; }
  .OptionsSummaryItem p {
    margin: 0; }
  .OptionsSummaryItem label {
    cursor: pointer; }
  .OptionsSummaryItem .column-price {
    padding-top: 0.5rem;
    white-space: nowrap; }
