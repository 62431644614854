/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
body {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  letter-spacing: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Aleo", serif; }

input {
  font-weight: 100; }

html,
body {
  font-size: 14px !important; }

h1 {
  font-size: 1.602em; }

h2 {
  font-size: 1.424em; }

h3 {
  font-size: 1.266em; }

h4 {
  font-size: 1.125em; }

h5 {
  font-size: 0.889em; }

h6 {
  font-size: 0.79em; }

.display-1 {
  font-size: 3.906em; }

.display-2 {
  font-size: 3.125em; }

.display-3 {
  font-size: 2.5em; }

.display-4 {
  font-size: 2em; }

@media screen and (min-width: 48rem) {
  h1 {
    font-size: 2.441em; }
  h2 {
    font-size: 1.953em; }
  h3 {
    font-size: 1.563em; }
  h4 {
    font-size: 1.25em; }
  .display-1 {
    font-size: 6em; }
  .display-2 {
    font-size: 5.5em; }
  .display-3 {
    font-size: 4.5em; }
  .display-4 {
    font-size: 3.5em; } }

@media screen and (min-width: 62rem) {
  html,
  body {
    font-size: 16px !important; } }

@media screen and (min-width: 75rem) {
  html,
  body {
    font-size: 16px !important; } }

h1,
h2 {
  margin-bottom: 2rem; }

h3,
h4,
h5,
h6 {
  margin-bottom: 1rem; }

/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
.ContractTemplateDetails {
  cursor: pointer;
  border: 1px solid #e9e9e9;
  padding: 2rem !important; }
  .ContractTemplateDetails:hover {
    background-color: #f5f5f5; }
  .ContractTemplateDetails.checked {
    padding: 2rem;
    background-color: #e9e9e9;
    -webkit-box-shadow: 0.25rem 0.25rem 0 0 #ccc;
    -moz-box-shadow: 0.25rem 0.25rem 0 0 #ccc;
    box-shadow: 0.25rem 0.25rem 0 0 #ccc; }
  .ContractTemplateDetails .template-read-more {
    font-family: 'Aleo'; }
    .ContractTemplateDetails .template-read-more__text {
      font-style: italic;
      font-size: 0.8rem; }
  .ContractTemplateDetails .contract-image {
    margin-bottom: 24px; }
    .ContractTemplateDetails .contract-image img {
      max-width: 100%;
      max-height: 200px;
      height: auto;
      width: auto; }
  .ContractTemplateDetails .contract-title {
    text-align: center; }
  .ContractTemplateDetails .bottom {
    display: flex;
    align-items: center;
    flex-flow: column; }
    .ContractTemplateDetails .bottom .contract-price h2 {
      font-family: "AleoBold", serif; }
  .ContractTemplateDetails .contract-list {
    font-weight: 200; }
    .ContractTemplateDetails .contract-list, .ContractTemplateDetails .contract-list__item {
      margin-left: 0;
      padding-left: 0;
      list-style-type: none; }
    .ContractTemplateDetails .contract-list__item {
      text-align: left;
      color: #000;
      font-size: 0.8rem; }
      .ContractTemplateDetails .contract-list__item:not(:first-child) {
        margin-top: 1rem; }
  .ContractTemplateDetails .service-info {
    margin-top: 2rem; }
  .ContractTemplateDetails .service-info-list {
    margin-top: 1rem; }

.spinner-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; }

.spinner-wrapper > div {
  width: 52px;
  height: 52px; }
