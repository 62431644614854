/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
body {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  letter-spacing: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Aleo", serif; }

input {
  font-weight: 100; }

html,
body {
  font-size: 14px !important; }

h1 {
  font-size: 1.602em; }

h2 {
  font-size: 1.424em; }

h3 {
  font-size: 1.266em; }

h4 {
  font-size: 1.125em; }

h5 {
  font-size: 0.889em; }

h6 {
  font-size: 0.79em; }

.display-1 {
  font-size: 3.906em; }

.display-2 {
  font-size: 3.125em; }

.display-3 {
  font-size: 2.5em; }

.display-4 {
  font-size: 2em; }

@media screen and (min-width: 48rem) {
  h1 {
    font-size: 2.441em; }
  h2 {
    font-size: 1.953em; }
  h3 {
    font-size: 1.563em; }
  h4 {
    font-size: 1.25em; }
  .display-1 {
    font-size: 6em; }
  .display-2 {
    font-size: 5.5em; }
  .display-3 {
    font-size: 4.5em; }
  .display-4 {
    font-size: 3.5em; } }

@media screen and (min-width: 62rem) {
  html,
  body {
    font-size: 16px !important; } }

@media screen and (min-width: 75rem) {
  html,
  body {
    font-size: 16px !important; } }

h1,
h2 {
  margin-bottom: 2rem; }

h3,
h4,
h5,
h6 {
  margin-bottom: 1rem; }

/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
.OptionEntry {
  list-style: none; }
  .OptionEntry .option-entry__description b {
    font-weight: 300; }
  .OptionEntry .option-entry__description .option-entry__info {
    margin-left: 0.375rem;
    color: #333; }
