/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
.App {
  margin-top: 0.625rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 2.5rem; }

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg); } }

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0); }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1); } }
  .App__padding-top {
    padding-top: 70px; }
  .App__loader {
    align-items: center;
    display: flex;
    justify-content: center; }
  .App__spinner {
    animation: sk-rotate 2s infinite linear;
    height: 40px;
    margin: 100px auto;
    position: relative;
    text-align: center;
    width: 40px; }
    .App__spinner-dot {
      animation: sk-bounce 2s infinite ease-in-out;
      background-color: #333;
      border-radius: 100%;
      display: inline-block;
      height: 60%;
      position: absolute;
      top: 0;
      width: 60%; }
      .App__spinner-dot-2 {
        animation-delay: -1s;
        bottom: 0;
        top: auto; }
