/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
body {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  letter-spacing: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Aleo", serif; }

input {
  font-weight: 100; }

html,
body {
  font-size: 14px !important; }

h1 {
  font-size: 1.602em; }

h2 {
  font-size: 1.424em; }

h3 {
  font-size: 1.266em; }

h4 {
  font-size: 1.125em; }

h5 {
  font-size: 0.889em; }

h6 {
  font-size: 0.79em; }

.display-1 {
  font-size: 3.906em; }

.display-2 {
  font-size: 3.125em; }

.display-3 {
  font-size: 2.5em; }

.display-4 {
  font-size: 2em; }

@media screen and (min-width: 48rem) {
  h1 {
    font-size: 2.441em; }
  h2 {
    font-size: 1.953em; }
  h3 {
    font-size: 1.563em; }
  h4 {
    font-size: 1.25em; }
  .display-1 {
    font-size: 6em; }
  .display-2 {
    font-size: 5.5em; }
  .display-3 {
    font-size: 4.5em; }
  .display-4 {
    font-size: 3.5em; } }

@media screen and (min-width: 62rem) {
  html,
  body {
    font-size: 16px !important; } }

@media screen and (min-width: 75rem) {
  html,
  body {
    font-size: 16px !important; } }

h1,
h2 {
  margin-bottom: 2rem; }

h3,
h4,
h5,
h6 {
  margin-bottom: 1rem; }

/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
.SectionIndicator {
  color: #999;
  font-size: 1.8rem; }
  .SectionIndicator.active {
    color: #128bb0; }
    .SectionIndicator.active .bottom-line {
      border-top-color: #128bb0; }
      .SectionIndicator.active .bottom-line .arrow-down {
        border-top-color: #128bb0;
        border-style: solid;
        border-width: 0.375rem; }
  .SectionIndicator .icon-title {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 0.4rem; }
    @media screen and (min-width: 36rem) {
      .SectionIndicator .icon-title .title {
        font-size: 1rem; } }
    @media screen and (min-width: 48rem) {
      .SectionIndicator .icon-title .title {
        font-size: 1.2rem; } }
    .SectionIndicator .icon-title .title {
      margin-bottom: 0; }
  .SectionIndicator .bottom-line {
    border-top: 0.375rem solid #e9e9e9;
    display: flex;
    justify-content: center; }
    .SectionIndicator .bottom-line .arrow-down {
      width: 0;
      height: 0;
      border-color: transparent; }
