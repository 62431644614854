/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
body {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  letter-spacing: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Aleo", serif; }

input {
  font-weight: 100; }

html,
body {
  font-size: 14px !important; }

h1 {
  font-size: 1.602em; }

h2 {
  font-size: 1.424em; }

h3 {
  font-size: 1.266em; }

h4 {
  font-size: 1.125em; }

h5 {
  font-size: 0.889em; }

h6 {
  font-size: 0.79em; }

.display-1 {
  font-size: 3.906em; }

.display-2 {
  font-size: 3.125em; }

.display-3 {
  font-size: 2.5em; }

.display-4 {
  font-size: 2em; }

@media screen and (min-width: 48rem) {
  h1 {
    font-size: 2.441em; }
  h2 {
    font-size: 1.953em; }
  h3 {
    font-size: 1.563em; }
  h4 {
    font-size: 1.25em; }
  .display-1 {
    font-size: 6em; }
  .display-2 {
    font-size: 5.5em; }
  .display-3 {
    font-size: 4.5em; }
  .display-4 {
    font-size: 3.5em; } }

@media screen and (min-width: 62rem) {
  html,
  body {
    font-size: 16px !important; } }

@media screen and (min-width: 75rem) {
  html,
  body {
    font-size: 16px !important; } }

h1,
h2 {
  margin-bottom: 2rem; }

h3,
h4,
h5,
h6 {
  margin-bottom: 1rem; }

.RegistrationNumberEntry {
  position: relative; }
  .RegistrationNumberEntry .regnumber-input-title {
    padding-bottom: 1.5rem; }
  .RegistrationNumberEntry .regnumber-input-container {
    height: 3.75rem;
    background-color: transparent;
    background-image: url("./Plate-DK-212x50-tr.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    position: relative; }
    .RegistrationNumberEntry .regnumber-input-container.SE, .RegistrationNumberEntry .regnumber-input-container.sv-SE {
      background-image: url("./Plate-S-212x50-tr.png"); }
    .RegistrationNumberEntry .regnumber-input-container.FI, .RegistrationNumberEntry .regnumber-input-container.fi-FI {
      background-image: url("./Plate-FIN-212x50-tr.png"); }
    .RegistrationNumberEntry .regnumber-input-container.NO, .RegistrationNumberEntry .regnumber-input-container.nb-NO {
      background-image: url("./Plate-N-212x50-tr.png"); }
    .RegistrationNumberEntry .regnumber-input-container .regnumber-input {
      width: 14.25rem;
      background: transparent;
      padding: 0;
      padding-left: 3rem;
      margin-left: 0.6rem;
      font-size: 2.5rem;
      line-height: 1.2;
      text-transform: uppercase;
      border: 0;
      font-family: "Arial", sans-serif;
      font-weight: bold;
      opacity: 0.9; }
    .RegistrationNumberEntry .regnumber-input-container .regnumber-input::placeholder {
      color: #888; }
  .RegistrationNumberEntry .spinner-container {
    position: relative;
    left: 2.1875rem;
    min-width: 22px;
    min-height: 22px; }
  .RegistrationNumberEntry a {
    color: #dc3545; }
