/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
.payment-form-wrapper {
  margin-top: 4rem; }
  .payment-form-wrapper .nav-wrapper {
    margin-top: 0.5rem; }

.payment-form__spinner-wrapper {
  margin-top: 3rem;
  display: flex;
  width: 100%;
  justify-content: center; }

.payment-form__spinner {
  width: 4em;
  height: 4em; }

.card-form__wrapper {
  margin-top: 3rem;
  margin-bottom: 2rem;
  border: 1px solid transparent;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 8px;
  border: 3px solid #ccc;
  border-radius: 5px; }
  .card-form__wrapper--focused {
    border: 3px solid #128bb0; }

.card-form__error-msg {
  color: #dc3545;
  display: block;
  min-height: 1.5em;
  line-height: 1.5em; }

.stripe-links {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  margin-left: 5px;
  text-decoration: underline dotted; }

.stripe-info {
  display: 'block';
  justify-content: 'space-between'; }
