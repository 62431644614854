/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
.Popup__box {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center; }

.Popup__inner {
  padding: 2rem;
  background-color: #e9e9e9;
  -webkit-box-shadow: 0.25rem 0.25rem 0 0 #ccc;
  -moz-box-shadow: 0.25rem 0.25rem 0 0 #ccc;
  box-shadow: 0.25rem 0.25rem 0 0 #ccc;
  padding: 1rem;
  min-width: 30%;
  min-height: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto; }
  .Popup__inner h4 {
    text-align: center; }
