/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
.PageHeader {
  text-align: center; }
  .PageHeader__message {
    color: #128bb0;
    margin-bottom: 3rem; }
  .PageHeader__branding-logo {
    display: block;
    max-width: 8.5625rem;
    max-height: 6.25rem; }
    @media screen and (min-width: 48rem) {
      .PageHeader__branding-logo {
        max-width: 12.84375rem;
        max-height: 9.375rem; } }
