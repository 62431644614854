/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
/*
 * Comvert px to rem
 * Usage: px-to-rem(16)
 * Usage: px-to-rem(16px)
 */
.pullUp {
  padding-top: 0 !important;
  margin-top: 0 !important; }

.SummaryPage .btn,
.OverviewPage .btn {
  font-weight: 100;
  font-size: 1rem;
  line-height: 2rem;
  text-decoration: none;
  padding-left: 1rem;
  padding-right: 1rem; }
  .SummaryPage .btn .navigation-arrow,
  .OverviewPage .btn .navigation-arrow {
    font-size: 2rem;
    vertical-align: top; }

.SummaryPage--empty,
.OverviewPage--empty {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.SummaryPage .terms-conditions-accept .is-invalid,
.OverviewPage .terms-conditions-accept .is-invalid {
  color: #dc3545; }

.SummaryPage .terms-conditions-accept label,
.OverviewPage .terms-conditions-accept label {
  cursor: pointer; }

.OverviewPage .spinner {
  margin-top: 3rem; }

.box-summary {
  margin-bottom: 0 !important;
  padding-bottom: 2rem !important; }

.summary {
  padding-top: 3rem !important;
  padding-bottom: 0.5rem; }

.summary-heading {
  padding-top: 1rem;
  padding-bottom: 0.5rem; }

.options-summary-container {
  padding-top: 0;
  padding-bottom: 0.5rem; }

/* Styles related to print layout of the summary */
@media print {
  body {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important; }
  .SummaryPage .OptionsSummaryItem {
    padding: 0.1rem 0.2rem;
    border-bottom: 1px dotted #adadad; }
    .SummaryPage .OptionsSummaryItem label {
      margin-bottom: 0.1rem; }
  .SummaryPage .print-legal {
    text-align: right; } }

h3 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1rem;
  padding-bottom: 0; }

.my-3,
.mb-3 {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important; }
